<template>
<section class="goods-manager">
    <el-form :inline="true" class="search-wrapper" :mode="searchMode">
        <el-form-item label="时间">
            <el-date-picker
                v-model="searchMode.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="搜索">
            <el-input v-model="searchMode.search" class="search-input" placeholder="订单号/客户昵称/客户手机号"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button class="search-btn" type="primary" @click="getList">搜索</el-button>
            <el-button @click="resetHandler">重置</el-button>
            <!-- <el-button @click="ExcelHandler">导出Excel</el-button> -->
        </el-form-item>
    </el-form>
    <h2 class="goods-title">商品列表</h2>
    <div class="goods-header flex space-between">
        <ul class="tab-wrapper">
            <li 
            :class="['tab-item',tabIndex==i?'tab-item_act':'']" 
            v-for="(item,i) in tabList" 
            @click="changeTabHandler(i)"
            :key="i">{{item.name}}
                <span v-show="i!=0"> ({{item.num}}) </span>
                <span v-show="i==0"> ({{allNum}}) </span>
            </li>
        </ul>
        <el-button @click="ExcelsHandler">批量导出</el-button>
    </div>

    <!-- table -->
    <el-table
        class="table-wrapper"
        :data="list"
        :header-cell-style="{color: '#000',fontWeigth: 'bold',background: '#FAFAFA',textAlign:'center'}"
        @selection-change="selectionChangeHandler"
        style="width:100%; min-height: 500px;">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column
            prop="_id"
            align="center"
            label="订单号">
        </el-table-column>
        <el-table-column
            label="商品"
            width="200">
            <template slot-scope="scope">
                <div class="flex">
                    <el-image :src="scope.row.goodsList[0].img" alt="" class="table-img">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                    <p class="table-name flex-item">
                        <span>{{scope.row.goodsList[0].productName}}</span>
                        <span>{{scope.row.goodsList[0].sku}}</span>
                    </p>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="数量">
            <template slot-scope="scope">
                <span>X{{scope.row.goodsList[0].productCount}}</span>
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="单价(元）">
            <template slot-scope="scope">
                <span>￥{{scope.row.goodsList[0].productPrice}}</span>
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="客户信息">
            <template slot-scope="scope">
                <p>{{scope.row.receiver.name}}</p>
                <p>{{scope.row.receiver.mobile}}</p>
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="收货地址">
            <template slot-scope="scope">
                {{scope.row.receiver.province}}{{scope.row.receiver.city}}{{scope.row.receiver.address}}
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="下单时间">
            <template slot-scope="scope">
               {{scope.row.createTimeM | formatDate}}
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="实付金额">
            <template slot-scope="scope">
                <span>￥{{(scope.row.totalPrice || 0)/100}}</span>
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="状态">
            <template slot-scope="scope">
                <span v-if="scope.row.status==0">待支付</span>
                <span v-if="scope.row.status==1">待审核</span>
                <span v-if="scope.row.status==2">待发货</span>
                <span v-if="scope.row.status==3">已发货</span>
                <span v-if="scope.row.status==4">已关闭</span>
                <span v-if="scope.row.status==5">已完成</span>
                <span v-if="scope.row.status==8">维权中</span>
            </template>
        </el-table-column> 
        <el-table-column
            align="center"
            label="操作">
            <template slot-scope="scope">
                <el-button type="text" v-if="scope.row.status==2" @click="sendGoodHandler(scope.row)">发货</el-button>
                <el-button type="text" v-else @click="viewDetails(scope.row)">详情</el-button>
            </template>
        </el-table-column>
    </el-table>
    <!-- 用户信息 -->

    <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15,20]"
        :page-size="rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>

    <!-- 发货 -->
    <el-dialog title="订单发货" :visible.sync="dialogSendVisible">
        <el-form :model="sendForm" :rules="sendRules" label-width="100px">
            <el-form-item label="快递公司" prop="deliveryName">
                <el-select v-model="sendForm.deliveryName" placeholder="请选择快递公司">
                    <el-option v-for="(item,i) in deliveryLists" :key="i" :label="item" :value="item"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="快递单号" prop="deliveryId">
                <el-input style="width: 216px;" v-model="sendForm.deliveryId" placeholder="请输入快递单号"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="sendCancelHandler">取 消</el-button>
            <el-button type="primary" @click="sendSureHandler">确 定</el-button>
        </div>
    </el-dialog>

    <!-- 订单详情&物流信息 -->
    <el-dialog class="mt-detail-dialog" title="" :visible.sync="dialogDetailVisible" @close="closeDetailDialog">
        <el-tabs class="mt-tabs" v-model="activeTabName" type="card" @tab-click="toggleTab">
            <el-tab-pane class="mt-tab-pane" label="订单详情" name="1">
                <el-row class="mt-detail-row">
                    <h4 class="mt-detail-title">客户信息</h4>
                    <div class="mt-detail-content flex flex-column">
                        <span class="mt-detail-text">昵称：{{ orderInfo.nickName }}</span>
                        <span class="mt-detail-text" v-if="orderInfo.receiver">所在地区：{{orderInfo.receiver.province}}{{orderInfo.receiver.city}}{{orderInfo.receiver.address}}</span>
                    </div>
                </el-row>
                <el-row class="mt-detail-row mt-order-info">
                    <h4 class="mt-detail-title">订单信息</h4>
                    <div class="mt-detail-content flex">
                        <span class="mt-detail-text">订单编号：{{ orderInfo._id }}</span>
                        <span v-show="orderInfo.PayTimeM" class="mt-detail-text">支付时间：{{ orderInfo.PayTimeM |formatDate }}</span>
                        <span class="mt-detail-text">创建时间：{{ orderInfo.createTimeM | formatDate}}</span>
                    </div>
                    <el-table class="mt-table" :data="orderInfo.goodsList" header-cell-class-name="mt-table-th__cell" cell-class-name="mt-table-td" style="width: 100%">
                        <el-table-column align="center" label="商品信息" prop="" min-width="200">
                            <template slot-scope="scope">
                                <div class="mt-td-content flex align-center">
                                    <!-- 2.8.2 版本才有该组件 2.11.0 才有大图预览功能 -->
                                    <el-image class="mt-product-img" :src="scope.row.img" filt="cover" :preview-src-list="[scope.row.img]">
                                        <!-- 自定义占位内容 -->
                                        <div slot="placeholder" class="image-slot flex flex-column align-center">
                                            <i class="el-icon-loading"></i>
                                        </div>
                                        <!-- 自定义加载失败内容 -->
                                        <div slot="error" class="mt-image-slot flex flex-column align-center">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                    </el-image>
                                    <div class="mt-product-info flex flex-column">
                                        <span class="mt-info-text">{{ scope.row.productName }}</span>
                                        <span class="mt-info-text">{{ scope.row.sku }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="数量" prop="">
                            <template slot-scope="scope">
                                <span>X{{ scope.row.productCount }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="单价" prop="">
                            <template slot-scope="scope">
                                <span>￥{{ scope.row.productPrice }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="mt-total-wrapper flex flex-column">
                        <span class="mt-total-text">商品总价：{{orderInfo.goodsPrice}}元</span>
                        <span class="mt-total-text">实收款：<em class="mt-official-receipts">{{(orderInfo.totalPrice ||0)/100}}</em>元</span>
                    </div>
                </el-row>
            </el-tab-pane>
            <el-tab-pane class="mt-tab-pane" label="物流信息" name="2">
                <el-row class="mt-detail-row">
                    <h4 class="mt-detail-title">物流信息</h4>
                    <div class="mt-detail-content flex flex-column">
                        <span class="mt-detail-text" v-if="orderInfo.receiver">收货地址：{{ orderInfo.receiver.province }}{{orderInfo.receiver.city }}{{ orderInfo.receiver.zone }}{{ orderInfo.receiver.address }}</span>
                        <span class="mt-detail-text">运送方式：快递</span>
                        <span class="mt-detail-text">物流公司名称：{{orderInfo.deliveryName || '暂无'}}</span>
                        <div class="mt-text-wrapper">
                            <span class="mt-detail-text" v-show="orderInfo.deliveryId">运单号：{{ orderInfo.deliveryId }}</span>
                            <el-button type="text" class="mt-check-logistics" v-show="orderInfo.deliveryId" @click="checkLogistics">查看物流</el-button>
                        </div>
                    </div>
                </el-row>
            </el-tab-pane>
        </el-tabs>
    </el-dialog>
    <!-- 订单详情&物流信息 -->
</section>
</template>

<script>
import {DELIVERY_LISTS} from '@/utils/constant.js';
import utils from '@/utils/utils.js';

let tempType = {};
let excelData; // 批量导出数据
export default {
    data(){
        return {
            tabIndex:0,
            tabList:[
                {
                    name:'全部',
                    num:0,
                    status:''
                },
                {
                    name:'待发货',
                    num:0,
                    status: 2,
                },
                {
                    name:'已发货',
                    num:0,
                    status: 3
                },
                {
                    name:'已完成',
                    num:0,
                    status: 5
                }
            ],
            list:[],
            currentPage:1,
            searchMode:{
                time:[],
                search:''
            },
            dialogSendVisible: false,
            sendForm: {
                deliveryName: '',
                deliveryId:''
            },
            sendRules:{
                deliveryName:[{required: true, message: '请选择快递公司', trigger: 'change'}],
                deliveryId:[{required: true, message: '请输入快递单号', trigger: 'blur'}],
            },
            deliveryLists: DELIVERY_LISTS,

            dialogDetailVisible: false, // 是否显示订单物流详情弹窗
            activeTabName: "1", // 激活的选项卡
            // 详情选项卡列表
            detailTabList: [
                {
                    label: "订单详情",
                    name: "1"
                },
                {
                    label: "物流信息",
                    name: "2"
                }
            ],
            orderInfo: {}, // 订单信息
            total:0,
            currentPage:1,
            rows:10,
            oldNum:0,
            sendId:0,
            openId:0, // 当前选择的订单客户id
        }
    },
    created(){
        this.getList();
        this.getTempType();
    },
    computed:{
        allNum(){
            return this.tabList[0].num<100? this.tabList[0].num: '99+';
        },
    }, 
    methods:{
        async getList(){
            const status = this.tabList[this.tabIndex].status;
            const rs = await this.$https.cloud({
                action:'order',
                type:'list',
                formData:{
                    page: this.currentPage,
                    rows: this.rows,
                    startTime: this.searchMode.time[0] || '',
                    endTime: this.searchMode.time[1] || '',
                    search: this.searchMode.search,
                    status: status
                }
            });
            if(rs.code!=0) {
                this.$message.error(rs.msg);
                return;
            }
            const list = rs.data.list;
            this.list = rs.data.list;
            const total = rs.data.total.list;

            let allNum = 0;
            total.forEach(item=>{
                allNum += item.num;
                if(item._id == 2){
                    this.tabList[1].num = item.num;
                }else if(item._id == 3){
                    this.tabList[2].num = item.num;
                }else if(item._id == 5){
                    this.tabList[3].num = item.num;
                }
            });

            this.tabList[0].num = allNum;
            this.list = list;
            if(list.length==0) return;
            const imgList = [];
            list.forEach(item=>{
                imgList.push({fileid: item.goodsList[0].img});
            });
            const fileList = await this.$https.getImagePath(imgList);
            fileList.forEach((item,i)=>{
                list[i].goodsList[0].img = item.download_url;
            });

            if(status){
               total.forEach(item=>{
                   if(item._id == status){
                       this.total = item.num;
                   }
               });
            }else{
                this.total = allNum;
            }

            this.list = list;
        },
        changeTabHandler(i){
            if(this.tabIndex==i) return;
            this.tabIndex = i;
            this.currentPage = 1;
            this.getList();
        },

        //  分页 ========
        handleSizeChange(e){
            this.rows = e;
            this.getList();
        },
        handleCurrentChange(e){
            this.currentPage = e;
            this.getList();
        },

        // 查看详情
        viewDetails(objOrderInfo) {
            this.orderInfo = objOrderInfo;
            let goodsPrice = objOrderInfo.goodsList[0].productPrice * objOrderInfo.goodsList[0].productCount;
            this.orderInfo.PayTimeM = objOrderInfo.transaction&&objOrderInfo.transaction.PayTimeM || ''
            this.orderInfo.goodsPrice = goodsPrice;
            this.dialogDetailVisible = true;
        },

        // 切换详情选项卡
        toggleTab(tab, event) {
            console.log(tab);
        },

        // 关闭详情弹窗的处理
        closeDetailDialog() {
            this.activeTabName = "1";
        },

        // 查看物流
        checkLogistics() {
            console.log("查看物流！");
            window.open(`http://kuaidi100.com/app/query/?coname=indexall&nu=${this.orderInfo.deliveryId}&callbackurl=${encodeURIComponent(window.location.href)}`);
        },

        sendGoodHandler(data){
            this.sendId = data._id;
            this.openId = data.openid;
            this.dialogSendVisible = true;
        },

        //  取消发货
        sendCancelHandler(){
            this.sendForm = {
                deliveryName: '',
                deliveryId:''
            };
            this.dialogSendVisible = false;
        },

        async sendSureHandler(){
            const rs = await this.$https.cloud({
                action:'order',
                type:'deliver',
                formData:{
                    _id: this.sendId,
                    deliveryName: this.sendForm.deliveryName,
                    deliveryId: this.sendForm.deliveryId,
                }
            });
            if(rs.code==0){
                this.$message.success(rs.msg);
                this.sendMessage();
                this.getList();
            }else{
                this.$message.error(rs.msg);
            }
            this.dialogSendVisible = false;
            this.sendForm = {
                deliveryName: '',
                deliveryId:''
            };
        },
        //  获取模块类型及id
        async getTempType(){
            const rs = await this.$https.cloud({
                    type:'getTempIds',
                },'subscribeCloudFun',false);
            
            if(rs.code==0){
                tempType = rs.data;
            }
        },
        //  发货成功通知
        async sendMessage(){
            await this.$https.cloud({
                type:'send',
                formData:{
                    orderId:  this.sendId,
                    tplId: tempType.deliver,
                    openId: this.openId
                }
            },'subscribeCloudFun',false)
        },

        formatStatus(status){
            let rs = '';
            switch (status) {
                case 0:
                    rs = '待支付'
                    break;
                case 1:
                    rs = '待审核'
                    break;
                case 2:
                    rs = '待发货'
                    break;
                case 3:
                    rs = '已发货'
                    break;
                case 4:
                    rs = '已关闭'
                    break;
                case 5:
                    rs = '已完成'
                    break;
                case 8:
                    rs = '维权中'
                    break;
            
                default:
                    break;
            }
            return rs;
        },

        selectionChangeHandler(data){
            console.log(data);
            excelData = data;
        },

        // 弃
        async ExcelHandler(){
            //  todo
            const rs = await this.$https.cloud({
                action:'order',
                type:'excelList',
                formData:{
                    startTime: this.searchMode.time[0] || '',
                    endTime: this.searchMode.time[1] || '',
                    search: this.searchMode.search,
                }
            });
            if(rs.code==0){

                this.tableToExcel(rs.data);
            }else{
                this.$message.error('导出excel出错');
            }
        },

        // 批量导出
        ExcelsHandler(){
            if(!excelData|| excelData.length==0){
                this.$message.error('请选择要导出的数据');
                return;
            }
            this.tableToExcel(excelData);
        },

        tableToExcel(data){
            try {
                
                let tableData = `订单号,商品,数量,单价（元）,客户信息,收货地址,下单时间,实付金额,状态\n`;
                console.log(utils);
                data.forEach(item=>{    
                    tableData += item._id+'\t,'+
                    item.goodsList[0].productName+'\t,'+
                    item.goodsList[0].productCount+'\t,'+
                    item.goodsList[0].productPrice+'\t,'+
                    item.receiver.name +item.receiver.mobile+'\t,'+
                    item.receiver.province+item.receiver.city+item.receiver.address+'\t,'+
                    utils.formatDate(item.createTimeM)+'\t,'+
                    (item.totalPrice?item.totalPrice/100:0)+'\t,'+  
                    this.formatStatus(item.status)+'\n'
                });
                let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(tableData);
                // console.log(tableData);
                // return;
                let link = document.createElement("a");
                link.href = uri;
                //对下载的文件命名
                link.download =  'ai管理系统订单报表'+utils.formatDate(new Date().getTime())+'.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                this.$message.error('导出excel出错');
            }
        },
        resetHandler(){
            this.searchMode={
                time:[],
                search:''
            };
            this.getList();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/common.scss';
 
.goods-manager {
    padding:  0 10px;
    background: #fff;

    .search-wrapper {
        padding-top: 20px;
        border-bottom: 2px solid #ccc;
        font-size: 14px;
        .search-input {
            width: 225px;
            display: inline-block;
            margin-right: 25px;
        }
    }

    .goods-title {
        font-size: 16px;
        font-weight: normal;
        line-height: 1;
        padding:  18px 0 13px 0;
    }

    .table-img {
        width: 44px;
        height: 44px;
        
        margin-right: 5px;
    }

    .goods-header {
        line-height: 40px;
        align-items: center;
        background: #fff;
        
        .tab-item {
            user-select: none;
            cursor: pointer;
            display: inline-block;
            margin: 0 2px;
            height:40px;
            line-height: 40px;
            font-size: 14px;
            color: #575757;
            padding: 0 10px;
            border: 1px solid rgba(0,0,0,0.15);
            border-bottom: none;
            border-radius:4px 4px 0px 0px;
            background:rgba(0,0,0,0.02);
        }

        .tab-item_act {
            background: $baseColor;
            color: #fff;
            border-color: $baseColor;
        }

        .add-good {
            display: inline-block;
            width:111px;
            height:32px;
            line-height: 32px;
            border: 1px solid $baseColor;
            border-radius:4px;
            font-size: 14px;
            color: $baseColor;
            text-align: center;
            margin-bottom: 7px;
            
        }
    }

    .table-wrapper {
        width: 100%;
        min-height: 500px;
        border-top: 1px solid #ccc;

        .table-thead {
            height: 54px;
            line-height: 54px;
            background: #FAFAFA;
            font-size: 14px;
            display: table;
            width: 100%;
        }
        .table-th {
            display: table-cell;
            text-align: center;
        }

        .table-row {
            border: 1px solid #eee;
            border-radius: 2px;
            margin-bottom: 20px;
            width: 100%;
            font-size: 14px;
        }

        .row-content {
            display: table;
            width: 100%;
            height: 40px;
            line-height: 40px;
            
        }

        .table-td {
            display: table-cell;
        }

        .goods-list {
            // border: 1px solid #eee;
            font-size: 14px;
        }

        .list-item {
            padding:  10px 20px;
            border-top: 1px solid #eee;
            .thumb {
                width: 45px;
                height: 45px;
                margin-right: 8px;
            }

            .good-info {
                span {
                    display: block;
                    line-height: 22px;
                }
            }

        }

        .table-btn {
            color: $baseColor;
        }
    }

    .pop-box {
        padding:  0 13px;
        background: #fff;
        line-height: 30px;

        .copy-btn {
            color: $baseColor;
        }
    }

    .pagination {
        padding:  10px;
        text-align: right;
    }

    // 详情弹窗 start
    .mt-detail-dialog {
        .mt-tab-pane {
            padding: 0 20px;
        }
    }

    .mt-detail-row {
        & + .mt-detail-row {
            margin-top: 10px;
        }
        .mt-detail-title {
            margin: 0;
            line-height: 25px;
        }

        .mt-detail-text {
            font-size: 12px;
            line-height: 25px;
        }

        &.mt-order-info  {
            .mt-detail-text {
                margin-right: 10px;
            }

            .mt-detail-content {
                flex-wrap: wrap;
            }

            .mt-total-wrapper {
                margin-top: 10px;
                text-align: right;
                .mt-total-text {
                    font-size: 12px;
                    line-height: 20px;

                    .mt-official-receipts {
                        color: #FF0202;
                        font-weight: bold;
                        font-style: normal;
                    }
                }
            }
        }

        .mt-table {
            margin-top: 10px;

            /* 图片预览样式 start */
            .mt-product-img {
                margin-right: 8px;
                width: 80px;
                height: 80px;
                border: 1px solid #eee;

                & + .mt-product-img {
                    margin-left: 8px;
                }

                .mt-image-slot {
                    justify-content: center;
                    height: 100%;

                    [class*="el-icon-"] {
                        color: #666;
                        font-size: 24px;
                    }
                }

                /* 修改大图预览组件icon太小的问题 */
                .el-image-viewer__actions__inner [class*="el-icon-"], 
                .el-image-viewer__actions__inner [class^="el-icon-"] {
                    font-size: 24px;
                }

                .el-image-viewer__close [class*="el-icon-"], 
                .el-image-viewer__close [class^="el-icon-"] {
                    color: #fff;
                    font-size: 40px;
                }
                /* 修改大图预览组件icon太小的问题 */
            }
            /* 图片预览样式 end */
        }

        .mt-check-logistics {
            margin-left: 10px;
        }
    }
    // 详情弹窗 end
}
</style>

<style lang="scss">
// 详情弹窗 start
.mt-detail-dialog {
    .el-dialog__body {
        padding-left: 0;
        padding-right: 0;
    }

    .mt-tabs {
        .el-tabs__header {
            padding-left: 20px;
        }

        // .el-tabs__item + .el-tabs__item {
        //     margin-left: 4px;
        // }

        .el-tabs__item {
            &:first-of-type {
                border-top-left-radius: 4px;
            }
            &:last-of-type {
                border-top-right-radius: 4px;
            }
        }
    
        .el-tabs__item.is-active {
            background-color: #1890FF;
            border-bottom-color: #1890FF !important;
            color: #fff;
        }

    }
}

.mt-detail-row {
    .mt-table {
        /* 解决表格在宽屏下最后一列单元格的右边框会消失的bug */
        .el-table__header,
        .el-table__body {
            width: auto !important;
        }
    }

    /* 修改表头行样式 */
    // .mt-table-th__row {
    //     border: 1px solid #eee;
    // }

    /* 修改表头单元格样式 */
    .mt-table-th__cell {
        // background-color: #eee !important;
        border-top: 1px solid #eee;
        color: #333;
    }

    .mt-table-th__cell:first-of-type {
        border-left: 1px solid #eee;
    }

    .mt-table-th__cell:nth-of-type(3) {
        border-right: 1px solid #eee;
    }

    /* 修改行的样式 */
    /* .mt-table-tr {
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
    } */

    /* 修改单元格样式 */
    .mt-table-td:first-child {
        border-left: 1px solid #eee;
    }

    .mt-table-td:last-child {
        border-right: 1px solid #eee;
    }

    /* 修改表格空数据时样式 */
    .mt-table .el-table__empty-block {
        border-left: 1px solid #eee; 
        border-right: 1px solid #eee;
        box-sizing: border-box;
    }
}
// 详情弹窗 end
</style>